.formsCont form input,
textarea {
  border: 1px solid #eeeeee;
  background-color: #f9f9f9;
  border-radius: 12px;
}

.BoxContact {
  border: 1px solid #eeeeee;
  background-color: #ffff;
}

.BoxContact .content {
  display: flex;
  align-items: center;
}
.BoxContact .content .icon {
  color: red;
  font-size: 25px;
}
.BoxContact .content p {
  color: #6b6b6b;
  font-size: 20px;
}
.formsCont .col-md-8{
  overflow: hidden;
}
.formsCont .col-md-4{
  overflow: hidden;
}
