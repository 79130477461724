.BenefitsBox {
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  border-radius: 12px;
  min-height: 200px;
  justify-content: center;
}
.Benefits .BenefitsBox h6 {
  font-size: 25px;
  text-align: center;
}
.AllCompany .Linklogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: 0.3s ease-in;
}
.AllCompany .Linklogo:hover {
  color: #dd3a3d;
  transition: 0.3s ease-in;
}
.AllCompany .Linklogo h6 {
  padding-top: 12px;
  font-size: 16px;
  text-align: center;
}
.AllCompany .Linklogo img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}
