.Footer {
  background-image: url(../images/Rectangle\ 33.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 9%;
}
.Footer .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #3c3736eb;
}
.Footer h6 {
  color: #818181;
}
.Footer .list-inline {
  padding: 0;
}
.Footer .list-inline a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease-in;
}
.Footer .list-inline a li {
  margin: 8px 0;
  font-weight: bold;
}
.Footer .list-inline a:hover {
  color: #00923f;
  transition: 0.3s ease-in;
}
.Footer .Socila {
  display: flex;
}
.Footer .Socila a {
  color: #fff;
  transition: 0.3s ease-in;
}
.Footer .Socila a:hover {
  color: #fc4f4f;
  transition: 0.3s ease-in;
}
.Footer .BoxSubscribtion {
  margin-top: -7%;
  position: relative;
}
.Footer .BoxSubscribtion .container {
  background-color: #efeded;
  padding: 2%;
  border-radius: 12px;
}
.Footer .BoxSubscribtion .container h3,
p {
  color: #4e4e4e;
}
.Footer .BoxSubscribtion .container .subscribe_form .input-group {
  border-radius: 12px;
  overflow: hidden;
}
.Footer .BoxSubscribtion .container .subscribe_form .input-group input {
  border-radius: 12px;
}
.Footer
  .BoxSubscribtion
  .container
  .subscribe_form
  .input-group
  .input-group-btn {
  padding: 0px 20px;
}
.Footer
  .BoxSubscribtion
  .container
  .subscribe_form
  .input-group
  .input-group-btn
  button {
  background-color: #00923f;
  color: #fff;
  transition: 0.3s ease-in;
}
.Footer
  .BoxSubscribtion
  .container
  .subscribe_form
  .input-group
  .input-group-btn
  button:hover {
  transition: 0.3s ease-in;
  background: #340f10e5;
  color: #fff;
}
