.Goals {
  position: relative;
}
.Goals .GreenVector {
  position: absolute;
  left: 0;
  top: 21%;
}
.Goals .RedVector {
  position: absolute;
  right: 0;
  top: 25%;
}
.Goals .VisionBox {
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 12px;
  min-height: 260px;
  justify-content: center;
}
.Goals .VisionBox h6 {
  font-size: 25px;
  text-align: center;
}
