.Banner {
  position: relative;
  height: 70vh;
  display: flex;
  align-items: center;
  background-size: cover;
  animation: Banner 4s;
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation-fill-mode: both;
}

@keyframes Banner {
  from {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
.Banner .ovelay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000cc;
}
.Banner h1 {
  font-size: 90px;
  text-align: center;
}

.Vision .VisionBox {
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 12px;
}

.counter .BoxCount {
  display: flex;
  background: #f2f1f1;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.counter .BoxCount h1 {
  color: #dd3a3d;
  font-size: 80px;
  font-weight: bold;
}

.Member .pinkBac {
  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Member .pinkBac h3 {
  color: #4f4f4f;
  margin: 16px 0;
}
.Member .pinkBac h6 {
  color: #00923f;
  font-size: 23px;
  margin-top: 11px;
}
.Member h1 {
  font-size: 50px;
  color: #4f4f4f;
}

.BoxMember {
  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 520px;
}
.BoxMember ul li {
  color: #000000;
  font-size: 18px;
}
.BoxMember .Time {
  display: flex !important;
  justify-content: space-evenly;
}
.BoxMember .Time h3 {
  color: #00923f;
}
