@media screen and (max-width: 1680px) {
  .Home .container {
    position: absolute;
    z-index: 11111;
    left: 0;
  } 
  
  .Home .Car {
    position: absolute;
    bottom: 0;
    left: 0;
    animation: Car 4s;
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation-fill-mode: both;
    overflow: hidden;
   
    transform: scaleX(1);

  } 

body[dir="ltr"] .Home .Car {
  position: absolute;
  left: 55%;
 
}

body[dir="ltr"] .home-car {
transform: scaleX(-1);
}


  

  @keyframes Car {
    from {
      width: 0%;
      overflow: hidden;
    }

    to {
      width: 45%;
      overflow: hidden;
    }
  }

  .partners .Company {
    width: 45%;
    position: absolute;
    left: 0;
    z-index: 1;
    top: 15%;
  }

  .partners .Company-ltr {
    width: 45%;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 15%;
  transform: scaleX(-1);
  }

}
@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1199px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
  .navbar {
    background: #3c3736;
  }
}
@media screen and (max-width: 767px) {
  .navbar {
    background: #3c3736;
  }
  .Affiliation h2{
    padding-bottom: 8%;
  }
  .Tourism img {
    height: 15vh;
    object-fit: cover;
}
.Complaint_Cards_red h3 {
  color: #333;
  font-size: 32px;
}
.RedButton{
  inline-size: fit-content;
}
.About p {
  margin-bottom: 43px;
}
}
@media screen and (max-width: 600px) {
}
@media screen and (max-width: 575px) {
  .navbar {
    background: #3c3736;
  }
  .Affiliation h2{
    padding-bottom: 8%;
  }
  .Tourism img {
    height: 15vh;
    object-fit: cover;
}
.Complaint_Cards_red h3 {
  color: #333;
  font-size: 32px;
}
.RedButton{
  inline-size: fit-content;
}
.About p {
  margin-bottom: 43px;
}
.About .car{
  display:none;
}
.Home .Car{
  display:none;
}
.Home .container{
  left: 0;
}
.swiper-slide img{
  height: 100vh;
  object-fit: cover;
}
.About #backcar{
  display:none;
}
.Member .pinkBac h3 {
    margin: 10px 0;
  font-size: 16px;
  text-align: center;
}
.Member .pinkBac h5{
  font-size: 12px;
  text-align: center;
}
.Goals .VisionBox h6 {
  font-size: 16px;
}
.partners .BoxeGreen{
  display: none;;
}
.mobilelogo{
  display: block!important;
}
.weblogo{
  display:none!important;
}
}
