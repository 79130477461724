.navbar {
  background: linear-gradient(180deg,
      #502801 -38.78%,
      rgba(221, 58, 61, 0) 83.65%);
  padding: 10px 0 !important;
  position: fixed !important;
  width: 100%;
  z-index: 11111111111111;
}

.nav-link {
  color: #ffffff !important;
  margin: 0 10px;
  font-weight: bold !important;
}

.nav-link:hover {
  color: #007bff;
  text-decoration: none;
}

.RedButton {
  background: #00923f !important;
  border: 1px solid #00923f !important;
  color: #fff !important;
  padding: 10px 50px !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  transition: 0.3s ease-in !important;
  flex-basis: fit-content;

}

.RedButton:hover {
  background: #fff !important;
  border: 1px solid #00923f !important;
  color: #00923f !important;
  cursor: pointer !important;
  transition: 0.3s ease-in !important;
}

.WhiteButton {
  background: #fff;
  color: #00923f;
  padding: 10px 50px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: 0.3s ease-in;
}

.WhiteButton:hover {
  background: #00923f;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.redText {
  color: #dd3a3d;
  font-weight: bold;
}

.activee {
  background: #3c3736;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
  height: auto;
  z-index: 2222222222222222;
}

.mobile-active {
  background: #3c3736;
}

.navbar-toggler {
  background-color: #ccbdbd !important;
  border: none;
}

.navbar-toggler:focus {
  background-color: #ccbdbd !important;
  box-shadow: none;
  ;
}

.mobilelogo {
  display: none !important;
}

.navbar-toggler-icon {
  background-color: #ccbdbd;
}