.Home {
  height: 100vh;
  overflow: hidden;
}

.Home .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #150909ad;
  z-index: 11111;
}

.Home h1 {
  color: #ffffff;
  font-size: 58px;
  /* text-align: -webkit-right; */
  line-height: 99px;
}

.Home .Line {
  border-top: 5px solid #00923f;
  width: 120px;
}

.Home .container {
  position: absolute;
  z-index: 11111;
  left: 15%;
}

body[dir="ltr"] .home-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-green-bg {
  right: 0;
}

.Home .Car {
  position: absolute;
  bottom: 0;
  left: 0;
  animation: Car 4s;
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation-fill-mode: both;
  overflow: hidden;
  z-index: 11111;
}

body[dir="ltr"] .Home .Car {
  position: absolute;
  left: 60%;
}

body[dir="ltr"] .home-car {
  transform: scaleX(-1);
}

body[dir="ltr"] .about-car {
  transform: scaleX(-1);
}

@keyframes Car {
  from {
    width: 0;
    overflow: hidden;
  }

  to {
    width: 40%;
    overflow: hidden;
  }
}

.Home .Car img {
  width: 100%;
}

.About {
  min-height: 80vh;
}

.About h1 {
  position: absolute;
  top: 0;
  font-size: 88px;
}

.About p {
  font-size: 23px;
  text-align: justify;
  margin-bottom: 122px;
}

.About .car {
  position: absolute;
  top: 34%;
  left: 9%;
}

body[dir="ltr"] .About .car {
  position: absolute;
  top: 34%;
  left: 14;
}

.About .car {
  position: absolute;
  top: 34%;
  left: 9%;
}

.About .car2 {
  position: absolute;
  top: 25%;
  right: 7%;
}

body[dir="ltr"] .About .car2 {
  position: absolute;
  top: 25%;
  right: 13%;
}

.partners {
  position: relative;
}

.main-bg-image {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  top: 0;
  border-radius: 0 10px;
}
body[dir="ltr"] .main-bg-image {
  transform: scaleX(-1);
}

.partners .Logos img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}

.partners h2 {
  color: #dd3a3d;
}

.partners .Company {
  width: 37%;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 19px;
  filter: opacity(0.5);
}

.partners .Company-ltr {
  width: 37%;
  position: absolute;
  right: 0;
  z-index: 0;
  top: 19px;
  filter: opacity(0.5);
  transform: scaleX(-1);
}

.partners .BoxeGreen {
  background: linear-gradient(180deg, #00923f 0%, #c3e5d2 27.5%, #ffffff 100%);
  position: absolute;
  left: 0;
  width: 400px;
  height: 500px;
  z-index: 0;
  top: 0;
  border-radius: 0 10px;
}

.partners .box-green-ltr {
  background: linear-gradient(180deg, #00923f 0%, #c3e5d2 27.5%, #ffffff 100%);
  position: absolute;
  right: 0;
  width: 400px;
  height: 500px;
  z-index: 0;
  top: 0;
  border-radius: 0 10px;
  transform: scaleX(-1);
}

.partners .col-md-6 {
  z-index: 11;
}

.Affiliation {
  background-image: url(../images/image-1920x1080.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.Affiliation .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(52, 46, 46, 0.95);
}

.Affiliation .Box {
  padding: 10px 20px;
  font-size: 16px;
  color: #000;
  background-color: #70202000;
  border: 1px solid;
  border-image-source: linear-gradient(
    211.33deg,
    #ffffff 2.4%,
    rgba(255, 255, 255, 0) 65.61%
  );
  border-image-slice: 1;
  border-radius: 36px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.Affiliation h6 {
  color: #ffa8a9;
}

.Affiliation h2 {
  color: #ffa8a9;
  font-size: 40px;
  line-height: 66px;
  padding-bottom: 18%;
}

.Team h2 {
  font-size: 40px;
  color: #4f4f4f;
}

.Team h6 {
  font-size: 20px;
  color: #dd3a3d;
}

.Team .pinkBac {
  background: rgb(207 207 207);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}

.Team .pinkBac h3 {
  color: #4f4f4f;
  margin-top: 7px;
}

.Team .pinkBac h6 {
  color: #00923f;
}

.News {
  overflow: hidden;
}

.News h6 {
  color: #00000082;
}

.News h2 {
  color: #dd3a3d;
  font-size: 40px;
}

.News .BackNews {
  background-image: url(../images/image-1920x720.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  position: relative;
  height: 100%;
}

.News .BackNews .overlay {
  background: #00000080;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.News .BackNews .ContentNews {
  padding: 60px;
}

.News .BackNews .ContentNews h3 {
  padding-bottom: 20px;
}

.Tourism {
  position: relative;
}

.Tourism .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #566c7d7d;
}

.Tourism img {
  height: 70vh;
  object-fit: cover;
}

.Complaint_Cards_green {
  background: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5%;
  border: none;
  transition: 0.3s ease-in;
}

.Complaint_Cards_green h3 {
  color: #333;
  font-size: 35px;
}

.Complaint_Cards_red {
  background: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5%;
  border: none;
  transition: 0.3s ease-in;
}

.Complaint_Cards_red h3 {
  color: #333;
  font-size: 35px;
}

.Complaint_Cards_green:hover {
  background: none;
  transform: scale(1.05);
  transition: 0.3s ease-in;
  border: 2px solid green;
}

.Complaint_Cards_red:hover {
  background: none;
  transform: scale(1.05);
  transition: 0.3s ease-in;
  border: 2px solid red;
}

.FormComblain {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  margin: auto;
}

.ModelShow {
  z-index: 99999999999999999999999999999999999999999999999;
}

.FormComblain label {
  color: green;
  font-weight: bold;
}

.FormComblain input[type="text"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.FormComblain input[type="submit"] {
  background-color: #be1c0d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.FormComblain input[type="submit"]:hover {
  background-color: #be1c0d;
}

.btn-close {
  margin: 0 !important;
}

.Comblain h2 {
  font-size: 40px;
  color: #4f4f4f;
}
